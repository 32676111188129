<template>
	<div class="all_detail">
		<div class="detail">
			<div class="detail_left">
				<div class="detail_left_one">
					<el-carousel trigger="click" height="440px" arrow="always">
						<el-carousel-item v-for="item in bannerArr1" :key="item.id">
							<img :src="item.thumb" @click="bannerArrChange(item)">
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="detail_left_one">
					<el-carousel trigger="click" height="440px" arrow="always">
						<el-carousel-item v-for="item in bannerArr2" :key="item.id">
							<img :src="item.thumb" @click="bannerArrChange(item)">
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
			<div class="detail_right">
				<div class="top">
					<div class="top_left">
						<div class="title" @click="backClick">
							{{$t('other.homePage')}}
						</div>
						<div class="img">
							<img src="../../assets/home_icons/front.png">
						</div>
						<div class="sub_title">{{$t('title.hotRecommand')}}</div>
					</div>
					<div class="top_right">
						<div class="all_shares">
							<div class="share_text">{{$t('home.share')}}：</div>
							<div class="all_share_items">
								<el-dropdown trigger="click">
									<div class="el-dropdown-link share_item" @click="shareClick(1)">
										<img src="../../assets/home_icons/share_wexin.png">
									</div>
									<el-dropdown-menu slot="dropdown">
										<div class="qr_code">
											<div>
												<vue-qr :text="wechatShareUrl" :size="148" :logoSrc="logoSrc"></vue-qr>
											</div>
											<div class="scan_text">{{$t('weChatScan')}}</div>
										</div>
									</el-dropdown-menu>
								</el-dropdown>
							

								<div class="share_item" @click="shareClick(3)">
									<img src="../../assets/home_icons/share_whatsapp.png">
								</div>
								<div class="share_item" @click="shareClick(4)" style="margin-right: 0;">
									<img src="../../assets/home_icons/share_email.png">
								</div>
							</div>

						</div>
						<div class="item" @click="collectClick">
							<div class="item_img">
								<img src="@/assets/home_icons/collect.png" v-if="inforDetail.is_collection==0">
								<img src="@/assets/home_icons/collected.png" v-else>
							</div>
							<div>{{$t('home.collect')}}</div>
						</div>
						<div class="item" @click="toInformClick">
							<div class="item_img">
								<img src="../../assets/home_icons/inform.png">
							</div>
							<div class="title">{{$t('home.report')}}</div>
						</div>
					</div>
				</div>
				<div class="carousel">
					<el-carousel trigger="click" height="350px" arrow="always">
						<el-carousel-item v-if="video">
							<video width="894" height="350" :src="video" :poster="cover" controls :autoplay="true"
								muted="">
							</video>
						</el-carousel-item>
						<el-carousel-item v-for="(item,index) in thumb" :key="index">
							<viewer :images="[item]">
								<img :src="item" :key="index" style="object-fit: contain;width: 894px;height: 350px;">
							</viewer>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="house_content">
					<div class="house_title">
						<div class="house_title_left">
							<div class="house_name">
								{{inforDetail.title}}
							</div>
							<div class="cate_name">{{inforDetail.cate_name}}</div>
							<div class="house_two">
								<div class="price" v-if="inforDetail.price!='0.00'">${{inforDetail.price}}</div>
								<div class="price_right">
									<img :src="inforDetail.company_logo_url" v-if="inforDetail.company_logo_url">
								</div>
							</div>
						</div>
						<div class="house_title_right">
							<div class="contact_item" @click="contactClick(1)" v-if="wechat">
								<div class="contact_item_img">
									<img src="../../assets/home_icons/weixin.png">
								</div>
								<div class="contact_item_text">
									{{$t('weiXinContact')}}
								</div>
							</div>
							<div class="contact_item" @click="contactClick(2)">
								<div class="contact_item_img">
									<img src="../../assets/home_icons/phone2.png">
								</div>
								<div class="contact_item_text">
									WhatsApp
								</div>
							</div>
							<div class="contact_item" @click="contactClick(4)">
								<div class="contact_item_img">
									<img src="../../assets/home_icons/email.png">
								</div>
								<div class="contact_item_text">
									{{$t('emailContact')}}
								</div>
							</div>
						</div>
					</div>
					<div class="house_info">
						<div class="house_view">
							<div class="house_view_img">
								<img src="../../assets/home_icons/view.png">
							</div>
							{{inforDetail.look_count}}
						</div>
						<div class="house_time">
							{{$t('home.releaseTime')}} {{inforDetail.create_time}}
						</div>
						<div class="house_id">
							ID: {{information_id}}
						</div>
						<div class="address">
							<img src="../../assets/home_icons/house.png">
							<div class="address_detail">
								{{inforDetail.addr_name}} {{inforDetail.area_name}}
							</div>
						</div>
					</div>
				</div>

				<div class="all_info">
					<div class="info_item" v-for="(item,index) in skuData" :key="index">
						<div class="info_item_title">{{item.key_name}}</div>
						<div class="info_item_content">{{item.select_value?item.select_value:item.value}}</div>
					</div>

					<div class="info_item" v-if="company_name">
						<div class="info_item_title">{{$t('companyName')}}</div>
						<div class="info_item_content">{{company_name}}</div>
					</div>
					<div class="info_item" v-if="mobile">
						<div class="info_item_title">{{$t('mobile')}}</div>
						<div class="info_item_content">{{mobile}}</div>
					</div>
					<div class="info_item" v-if="contacts">
						<div class="info_item_title">{{$t('contacts')}}</div>
						<div class="info_item_content">{{contacts}}</div>
					</div>

				</div>
				<div class="home_detail">
					<div class="home_detail_content">
						{{inforDetail.detail}}
					</div>
					<div class="home_detail_imgs">
						<div class="detail_img">
							<viewer :images="thumb">
								<img :src="item" v-for="(item,index) in thumb" :key="index">
							</viewer>
						</div>
					</div>
				</div>

				<div class="detail_comments">
					<div class="detail_comments_top">
						<div class="img">
							<img src="../../assets/home_icons/title_logo.png">
						</div>
						<div class="text">{{$t('title.allComments')}}({{totalComment}})</div>
					</div>
					<div class="all_comments">
						<div class="comment_item" v-for="(item,index) in commentList" :key="index">
							<div class="comment_item_top">
								<div class="comment_item_top_left">
									<img :src="item.user_head_img">
								</div>
								<div class="comment_item_top_right">
									<div class="comment_name">{{item.user_name}}</div>
									<div class="comment_time">{{item.create_time}}</div>
								</div>
							</div>
							<div class="comment_item_bottom">
								{{item.content}}
							</div>
						</div>
					</div>
				</div>

				<div class="detail_bottom">
					<div class="detail_bottom_left" @click="checkMore">
						<template v-if="totalComment>commentList.length"> {{$t('checkMore')}}</template>
					</div>
					<div class="detail_bottom_right" @click="sayClick">
						<img src="../../assets/home_icons/say.png" v-if="lang=='zh'">
						<img style="width: 180px;" v-else src="../../assets/home_icons/say_bnm.png">
					</div>
				</div>
			</div>
		</div>


		<!-- 举报 -->
		<el-dialog title="" :visible.sync="dialogVisible" width="38%" :append-to-body="true">
			<div slot="title">
				<div class="title_all">
					<div class="title_img"><img src="@/assets/login/flag.png"></div>
					<div>{{$t('home.report')}}</div>
				</div>
			</div>
			<div class="dialog_content">
				<div class="inform_title">{{$t('home.reportContent')}}</div>
				<div class="textarea" @click="textareaClick">
					<el-input type="textarea" :autosize="true" :placeholder="$t('home.provideMoreInformation')"
						v-model="content" maxlength="200" ref="textareaRef">
					</el-input>
					<div class="count">{{content.length}}/200</div>
				</div>
				<div class="inform_title">{{$t('home.uploadPhoto')}}</div>
				<div class="all_imgs">
					<el-upload :action="uploadImgUrl" list-type="picture-card" :on-preview="handlePictureCardPreview"
						:on-remove="handleRemove" :limit="3" :before-upload="beforeUpload" :on-change="onChange"
						:multiple="true">
						<i class="el-icon-plus"></i>
						<div slot="tip" class="el-upload__tip">{{$t('home.atMost')}}3{{$t('home.open')}}</div>
					</el-upload>
					<el-dialog :visible.sync="previewImgDialog" :modal="false">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
				</div>
				<div class="btn" @click="btnClick">{{$t('home.submit')}}</div>
				<div class="tip">{{$t('home.informTips')}}</div>
			</div>
		</el-dialog>



		<el-dialog title="" :visible.sync="wechatVisible" width="30%">
			<div slot="title">
				<div class="title_all">
					<div class="title_img"><img src="@/assets/login/flag.png"></div>
					<div>{{$t('weiXinContact')}}</div>
				</div>
			</div>
			<div class="wechat">
				<img src="../../assets/home_icons/weixin.png">
				{{$t('contactWXId')}}
			</div>
			<div class="copy">
				<div id="copy_left">{{wechat}}</div>
				<div data-clipboard-target="#copy_left" class="copy_right" @click="copyClick">{{$t('copy')}}</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		uploadImgUrl,
		product_host
	} from '@/common/utils/config.js';
	import vueQr from 'vue-qr';
	export default {
		components: {
			vueQr
		},
		data() {
			return {
				uploadImgUrl: uploadImgUrl,
				dialogVisible: false,
				previewImgDialog: false, //预览图片
				dialogImageUrl: '', //预览图片地址

				inforDetail: '', //信息详情
				skuData: '', //
				thumb: [], //
				commentList: [], //评论列表
				totalComment: '', //所有评论数

				video: '', //视频地址
				cover: '', //封面地址

				content: '', //举报内容
				img_list: [], //上传图片列表

				isFlag: false,

				pageNo: 1,
				pageSize: 10,

				bannerArr1: [],
				bannerArr2: [],

				lang: '',

				wechatVisible: false, //复制微信号

				shareImageUrl: '', //页面地址

				contacts: '', //联系人
				wechat: '', //微信号
				whatsApp: '', //whatsApp
				mobile: '', //手机号
				user_email: '', //邮箱号
				company_name: '', //公司名称

				detailUserInfor: '', //发布信息的用户信息
				information_id: '',
				shareHref: '', //分享的链接

				wechatShareUrl: '',
				logoSrc: 'https://www.publichino.com/uploads/images/d1/13b60ffc609e27500ae05e1d16121a.png',

				subject: '巴拿马中文广告平台-Publichino.com',
				zhInterest: '我对您的广告很有感兴趣，请您联系我！',
				spInterest: 'Estoy muy interesado en su anuncio, por favor contacte conmigo!',
				userData:'',
				release_userId:'',
			}
		},
		mounted() {
			this.information_id = this.$route.query.information_id;
			this.lang = localStorage.getItem('lang');
			this.wechatShareUrl = 'https://www.publichino.com/weChatShare?information_id=' + this.information_id;

			this.getInforDetailDetail();
			this.getComments();
			this.getAds(1, 10);
			this.getAds(2, 11);
			window.addEventListener("setItemEvent", (e) => {
				if (e.key === "lang") {
					this.lang = e.newValue;
					this.getInforDetailDetail();
				}
			})

			this.shareHref = window.location.href + '&lang=' + this.lang;
			console.log(1111)
		},
		methods: {
			// getUserInfo() {
			// 	this.$http.userInfo().then(res => {
			// 		if (res.code == 1) {
			// 			console.log("userInfor", res);
			// 			this.userData = res.data;
			// 		} else {
			// 			this.$message.error(res.msg);
			// 		}
			// 	})
			// },
			// 轮播图切换事件
			bannerArrChange(item) {
				console.log(item);
				let ads_type = item.ads_type;
				let bind_information_id = item.bind_information_id;
				let href = item.href;
				if (ads_type == 1) {
					if (href) {
						window.open(href)
					}
				} else {
					this.$router.push({
						path: '/home/detail',
						query: {
							information_id: bind_information_id,
						}
					});
				}
			},
			// 复制微信号
			copyClick() {
				var clipboard = new this.Clipboard('.copy_right');
				clipboard.on('success', e => {
					this.$message.success(this.$t('copySuccess'))
					// 释放内存  
					clipboard.destroy()
				})
			},
			// 举报内容点击
			textareaClick() {
				this.$nextTick(() => {
					this.$refs.textareaRef.focus()
				});
			},
			// 获取轮播图
			getAds(type, typeid) {
				this.$http.getAds({
					typeid,
				}).then(res => {
					if (res.code == 1) {
						switch (type) {
							case 1:
								this.bannerArr1 = res.data;
								break;
							case 2:
								this.bannerArr2 = res.data;
								break;
						}
					} else {
						this.$message.info(res.msg);
					}
				})
			},
			// 获取信息详情
			getInforDetailDetail() {
				let userId = this.$store.state.userinfo.id;
				console.log(userId);
				this.$http.informationDetail({
					user_id: userId,
					information_id: this.information_id,
				}).then(res => {
					if (res.code == 1) {
						this.inforDetail = res.data;
						this.skuData = res.data.sku_data;
						console.log("嘿嘿嘿", res.data.sku_data);
						let newSkuData = [];
						this.skuData.forEach((item, index) => {
							if ((item.information_type == 1 && !item.value) || (item.information_type ==
									3 && !item.value)) {} else {
								newSkuData.push(item);
							}
						})
						newSkuData.forEach(item => {
							if (item.sign == 'Precio') {
								item.value = '$' + item.value;
							}

							if (item.sign == 'Mantenimientos') {
								item.value = '$' + item.value;
							}
						})
						this.skuData = newSkuData;

						console.log("数组呀", this.skuData);

						this.thumb = res.data.thumb;
						this.video = res.data.video;
						this.cover = res.data.cover;


						// 联系方式
						this.release_userId=res.data.user_id;
						// console.log(res.data.user_id,'家航空局');
						this.detailUserInfor = this.inforDetail.user_info;
						this.contacts = this.detailUserInfor.contacts;
						this.mobile = this.detailUserInfor.mobile_code + this.detailUserInfor.mobile;
						this.whatsApp = this.detailUserInfor.whats_code + this.detailUserInfor.whats_app;
						this.wechat = this.detailUserInfor.wechat;
						this.user_email = this.detailUserInfor.user_email;
						this.company_name = this.detailUserInfor.company_name;
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 获取评论
			getComments() {
				this.$http.commentInformationList({
					pageNo: this.pageNo,
					pageSize: this.pageSize,
					information_id: this.information_id,
				}).then(res => {
					if (res.code == 1) {
						this.totalComment = res.data.total;
						this.commentList = this.commentList.concat(res.data.data);
					} else {
						this.$message.error(res.msg);
					}
				})
			},



			backClick() {
				this.$router.push('/');
			},
			// 图片删除
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			// 图片预览
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.previewImgDialog = true;
			},
			// 上传之前
			beforeUpload(file) {
				const fileName = file.name
				const fileType = fileName.substring(fileName.lastIndexOf('.'));
				if (
					fileType === '.jpg' ||
					fileType === '.png' ||
					fileType === '.jpeg' ||
					fileType === '.bmp' ||
					fileType === '.gif'
				) {

				} else {
					this.$message.error(this.$t('pleaseUploadTheCorrectImageType'));
					return false;
				}
			},
			// 上传图片成功
			onChange(file, fileList) {
				console.log(file);
				console.log(fileList);
				this.img_list = fileList;
			},
			// 分享
			shareClick(val) {
				if (!this.$store.state.userinfo) {
					this.$message.error(this.$t('other.noLoginPleaseToLogin'));
					setTimeout(() => {
						this.$router.push({
							path: "/accountLogin"
						});
					}, 1500)
					return;
				}


				switch (val) {
					case 1:

						break;
					case 2:
						break;
					case 3:

						let whatsAppShareUrl = 'https://api.whatsapp.com/send?text=' + this.shareHref;
						window.open(whatsAppShareUrl);
						break;
					case 4:
						let emailShareUrl = "mailto:?body=" + this.shareHref + '&subject=' + this.subject;
						window.location.href = emailShareUrl;
						break;
				}
			},
			// 联系发布人
			contactClick(val) {
				switch (val) {
					case 1:
						this.wechatVisible = true;
						break;
					case 2:
						let whatsApp = '+' + this.whatsApp;
						let contactHref = this.zhInterest + '\n' + this.spInterest + '\n' + this.shareHref;
						let whatsAppContactUrl = 'https://api.whatsapp.com/send?phone=' + whatsApp + '&text=' +
							encodeURIComponent(contactHref);;

						window.open(whatsAppContactUrl);
						break;
					case 3:
						break;
					case 4:
						let body = this.zhInterest + '%0A' + this.spInterest + '%0A' + this.$t('advertiseLink') + ':' +
							'%0A' + this
							.shareHref;
						let emailContactUrl = "mailto:" + this.user_email + '?subject=' + this.subject + '&body=' + body;
						window.location.href = emailContactUrl;
						break;
				}
			},
			//提价举报
			toInformClick() {
				if (!this.$store.state.userinfo) {
					this.$message.error(this.$t('other.noLoginPleaseToLogin'));
					setTimeout(() => {
						this.$router.push({
							path: "/accountLogin"
						});
					}, 1500)
					return;
				}

				this.dialogVisible = true;
			},

			// 提交举报
			btnClick() {

				if (this.isFlag) return;

				if (!this.content) {
					this.$message.error(this.$t('tips.theReportContentCannotBeEmpty'));
					return;
				}

				if (this.img_list.length == 0) {
					this.$message.error(this.$t('tips.photoCannotBeEmpty'));
					return;
				}

				this.isFlag = true;

				let thumb = this.img_list.map(item => item.response.data[0].id).join(',');
				console.log(thumb);

				let data = {
					content: this.content,
					information_id: this.information_id,
					thumb,
				}
				this.$http.inform(data).then(res => {
					if (res.code == 1) {
						this.$message.success(res.msg);
						this.isFlag = false;
						this.dialogVisible = false;
					} else {
						this.isFlag = false;
						this.$message.error(res.msg);
					}
				})
			},
			//查看更多
			checkMore() {
				this.pageNo++;
				this.getComments()
			},
			// 收藏和取消收藏
			collectClick() {
				if (!this.$store.state.userinfo) {
					this.$message.error(this.$t('other.noLoginPleaseToLogin'));
					setTimeout(() => {
						this.$router.push({
							path: "/accountLogin"
						});
					}, 1500)
					return;
				}

				let type = this.inforDetail.is_collection == 0 ? 1 : 2;
				this.$http.collectionInformation({
					information_id: this.information_id,
					type,
				}).then(res => {
					if (res.code == 1) {
						this.$message.success(res.msg);
						this.getInforDetailDetail();
					} else {
						this.$message.info(res.msg);
					}
				})
			},
			// 我也说一句
			sayClick() {
				if (!this.$store.state.userinfo) {
					this.$message.error(this.$t('other.noLoginPleaseToLogin'));
					setTimeout(() => {
						this.$router.push({
							path: "/accountLogin"
						});
					}, 1500)
					return;
				}

			   console.log(this.$store.state.userinfo);
			   if(this.release_userId==this.$store.state.userinfo.id){
					this.$message.error(this.$t('home.commentText'));
					return false;
			   }
			   
				this.$prompt(this.$t('home.inputComment'), this.$t('my.tips'), {
					confirmButtonText: this.$t('home.send'),
					cancelButtonText: this.$t('cancel'),
					inputPlaceholder: this.$t('home.inputComment'),
					inputPattern: /^\S/,
					inputErrorMessage: this.$t('tips.commentsCannotBeEmpty'),
				}).then(({
					value
				}) => {
					this.postComment(value);
				}).catch(() => {

				});
			},
			// 发表评论
			postComment(value) {
				console.log("0-------8888");
				console.log("评论来了哈哈哈");

				if (!value) {
					this.$message.info(this.$t('tips.commentsCannotBeEmpty'));
					return;
				}

				this.$http.addComment({
					content: value,
					information_id: this.information_id,
				}).then(res => {
					if (res.code == 1) {
						this.$message.success(res.msg);
						this.pageNo = 1;
						this.commentList = [];
						this.getComments();
					} else {
						this.$message.error(res.msg);
					}
				})
			},
		}
	}
</script>

<style scoped="" lang="less">
	.title_all {
		display: flex;
		align-items: center;
		font-size: 20px;
		font-weight: bold;

		.title_img {
			margin-right: 8px;

			img {
				object-fit: contain;
				display: flex;
				align-items: center;
				width: 24px;
				height: 24px;
			}
		}
	}

	.dialog_content {
		text-align: left;

		.inform_title {
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			margin-bottom: 16px;
		}

		.textarea {
			margin-bottom: 24px;
			// width: 604px;
			width: 90%;
			height: 200px;
			background: #F6F7F9;
			padding: 24px;
			position: relative;

			/deep/ .el-textarea {
				textarea {
					outline: none;
					background: #F6F7F9;
					border: none;
					font-size: 14px;
					resize: none;
					font-family: Microsoft YaHei;
				}
			}

			.count {
				font-size: 12px;
				color: #999999;
				position: absolute;
				bottom: 16px;
				right: 16px;
			}
		}


		/deep/ .el-upload--picture-card {
			width: 140px;
			height: 140px;
		}

		/deep/ .el-upload__tip {
			width: 140px;
			text-align: center;
		}

		.btn {
			width: 112px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			background: #E60012;
			opacity: 1;
			border-radius: 20px;
			font-size: 14px;
			margin: 32px auto 24px;
			color: #FFFFFF;

			&:hover {
				cursor: pointer;
			}
		}

		.tip {
			margin: 0 auto;
			word-break: break-all;
			max-width: 466px;
			font-size: 14px;
		}
	}


	.all_detail {
		background: #F6F7F9;
		padding-top: 24px;
	}

	.detail {
		box-sizing: border-box;
		width: 1200px;
		margin: 0 auto;
		display: flex;

		.detail_left {
			width: 232px;

			.detail_left_one {
				margin-bottom: 16px;
			}

			/deep/ .el-carousel__arrow {
				top: 72%;
			}

			/deep/ .el-carousel__arrow--left,
			/deep/ .el-carousel__arrow--right {
				width: 24px;
				height: 24px;
			}
		}

		.detail_right {
			margin-left: 10px;
			margin-bottom: 64px;
			width: 958px;
			background-color: #FFFFFF;
			padding: 37px 32px;

			.top {
				display: flex;
				justify-content: space-between;

				.top_left {
					display: flex;
					font-size: 14px;

					.title {
						color: #999999;
					}

					.title:hover {
						cursor: pointer;
					}

					.img {
						margin: 0 8px;

						img {
							width: 8px;
							height: 8px;
						}
					}

					.sub_title {
						color: #333333;
					}
				}


				.top_right {
					display: flex;
					align-items: center;
					font-size: 14px;


					.all_shares {
						display: flex;
						align-items: center;

						.share_text {
							color: #333333;
							font-size: 14px;
							margin-right: 12px;
						}

						.all_share_items {
							display: flex;

							.share_item {
								cursor: pointer;
								margin-right: 18px;

								img {
									width: 22px;
									height: 22px;
								}

								// &:last-child {
								// 	margin-right: 0;
								// }
							}

						}
					}

					.item {
						margin-left: 36px;

						&:hover {
							cursor: pointer;
						}

						.item_img {
							img {
								width: 22px;
								height: 22px;
							}
						}

						.item_title {
							font-size: 14px;
							color: #333333;
						}

					}
				}
			}


			.carousel {
				margin-top: 24px;

				img {
					object-fit: contain;
				}
			}
		}
	}

	.house_content {
		box-sizing: border-box;
		padding: 20px 0 32px;
		border-bottom: 1px solid #EEEEEE;

		.house_title {
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;

			.house_title_left {
				padding-right: 24px;

				.house_name {
					font-weight: bold;
					font-size: 18px;
					text-align: left;
					max-width: 600px;
					// word-break: break-all;
					// text-overflow: ellipsis;
					// display: -webkit-box;
					// -webkit-box-orient: vertical;
					// -webkit-line-clamp: 2;
					/* 这里是超出几行省略 */
					// overflow: hidden;
					line-height: 24px;
					word-break: break-all;
				}

				.cate_name {
					margin-top: 24px;
					text-align: left;
					font-size: 14px;
					font-weight: bold;
				}

				.house_two {
					width: 600px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 20px 0;


					.price {
						color: #E60012;
						font-size: 16px;
						font-weight: bold;
						margin-right: 80px;
					}

					.price_right {

						img {
							object-fit: contain;
							width: 164px;
							height: 124px;
						}
					}

				}

			}

			.house_title_right {
				display: flex;
				justify-content: space-around;
				align-items: center;
				background-color: rgba(230, 0, 18, 0.05);
				// width: 366px;
				height: 83px;

				.contact_item {
					cursor: pointer;
					width: 90px;
					text-align: center;
					font-size: 14px;
					color: #333333;

					.contact_item_text {
						margin-top: 4px;
					}
				}
			}
		}


		.address {
			margin-left: 70px;
			display: flex;
			align-items: center;

			img {
				display: flex;
				align-items: center;
				width: 18px;
				height: 18px;
			}

			.address_detail {
				text-align: left;
				max-width: 300px;
				word-break: break-all;
				margin-left: 8px;
				line-height: 19px;
			}
		}

		.house_info {
			display: flex;
			align-items: center;
			font-size: 14px;

			.house_view {
				display: flex;
				align-items: center;

				.house_view_img {
					display: flex;
					align-items: center;

					img {
						margin-right: 8px;
						width: 18px;
						height: 18px;
					}
				}
			}

			.house_time {
				margin-left: 70px;
				color: #666666;
			}

			.house_id {
				margin-left: 70px;
				color: #666666;
			}

		}
	}

	.all_info {
		display: flex;
		flex-wrap: wrap;
		padding: 32px 0;
		border-bottom: 1px solid #EEEEEE;

		.info_item {
			font-size: 14px;
			margin-right: 20px;
			margin-bottom: 24px;
			border-right: 1px solid #cecece;
			padding-right: 20px;

			&:last-child {
				border-right: none;
			}

			.info_item_title {
				color: #888888;
			}

			.info_item_content {
				margin-top: 8px;
				color: #333333;
			}
		}


	}

	.home_detail {
		padding-bottom: 32px;
		border-bottom: 1px solid #EEEEEE;

		.home_detail_content {
			margin: 32px 0;
			line-height: 26px;
			color: #666666;
			font-size: 14px;
			text-align: left;
			word-break: break-all;
		}

		.home_detail_imgs {
			display: flex;
			flex-wrap: wrap;
			text-align: left;

			.detail_img {

				img {
					width: 216px;
					height: 192px;
					object-fit: cover;
					margin-right: 10px;
					margin-bottom: 10px;

					&:nth-of-type(4n) {
						margin-right: 0;
					}

				}
			}
		}
	}

	.detail_comments {
		padding-top: 32px;

		.detail_comments_top {
			display: flex;
			align-items: center;

			.img {
				display: flex;
				align-items: center;

				img {
					width: 18px;
					height: 18px;
				}
			}

			.text {
				margin-left: 8px;
				color: #333333;
				font-weight: bold;
				font-size: 18px;
			}
		}

		.all_comments {
			margin-top: 32px;
			text-align: left;

			.comment_item {
				padding-top: 26px;

				.comment_item_top {
					display: flex;

					.comment_item_top_left {
						img {
							width: 48px;
							height: 48px;
							border-radius: 50%;
						}
					}

					.comment_item_top_right {
						margin-left: 12px;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						text-align: left;

						.comment_name {
							font-size: 16px;
						}

						.comment_time {
							color: #999999;
							font-size: 12px;
						}
					}
				}


				.comment_item_bottom {
					margin-left: 58px;
					color: #333333;
					padding: 16px 0;
					border-bottom: 1px solid #EEEEEE;
					font-size: 14px;
					word-break: break-all;
				}
			}
		}
	}

	.detail_bottom {
		display: flex;
		justify-content: space-between;
		padding-top: 32px;

		.detail_bottom_left {
			color: #4177DD;
			font-size: 14px;
			margin-left: 48px;

		}

		.detail_bottom_left:hover {
			cursor: pointer;
		}

		.detail_bottom_right {

			&:hover {
				cursor: pointer;
			}

			// width: 140px;
			// height: 40px;
			// background-image: url(../../../assets/home_icons/check_more.png);
			// background-repeat: no-repeat;
			// background-size: 100% 100%;
			img {
				width: 140px;
				height: 40px;
			}
		}
	}

	.wechat {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;

		img {
			margin-right: 12px;
			width: 24px;
			height: 24px;
		}
	}

	.copy {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 90%;
		height: 72px;
		background: #F8F8FF;
		margin: 48px auto 0;
		padding: 0 36px;
		box-sizing: border-box;
		font-size: 16px;



		.copy_left {
			font-weight: bold;
			color: #333333;
		}

		.copy_right {
			cursor: pointer;
			color: #4177DD;
		}
	}

	.qr_code {
		display: inline-block;
		text-align: center;

		.scan_text {
			margin-top: -5px;
			font-size: 12px;
		}
	}
</style>
